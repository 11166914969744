import {css} from '@emotion/react';

const fillStyle = '#4D5EFF';

// 圆公共样式
const OStyle = css`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  border: 0.8px solid rgba(26, 34, 48, 0.4);
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: 50%;
  }
`;

// 左关联圆样式
const leftCheckCircle1 = css`
  background: ${fillStyle};
`;
const leftCheckCircle2 = css`
  left: -8px;
`;

// 内关联圆样式
const middleCheckCircle2 = css`
  left: -8px;
  &::before {
    left: -8px;
    background: ${fillStyle};
  }
`;

// 无关联圆样式
const noCheckCircle1 = css`
  border: 0.8px dashed rgba(26, 34, 48, 0.4);
`;
const noCheckCircle2 = css`
  left: -8px;
  border: 0.8px dashed rgba(26, 34, 48, 0.4);
`;

// 容器样式
const wraperStyle = css`
  display: flex;
  position: relative;
`;

// 左关联组合样式
export const leftCheckStyle = css`
  ${wraperStyle}
  .circle {
    ${OStyle}
  }
  .circle1 {
    ${leftCheckCircle1}
  }
  .circle2 {
    ${leftCheckCircle2}
  }
`;

// 内关联组合样式
export const middleCheckStyle = css`
  ${wraperStyle}
  .circle {
    ${OStyle}
  }
  .circle2 {
    ${middleCheckCircle2}
  }
`;

// 无关联组合样式
export const noCheckStyle = css`
  ${wraperStyle}
  .circle {
    ${OStyle}
  }
  .circle1 {
    ${noCheckCircle1}
  }
  .circle2 {
    ${noCheckCircle2}
  }
`;
