import React from 'react';

import useSafeState from '../../hooks/useSafeState';
import {GlobalTooltip} from '../GlobalTooltip';
import TwiceCheckButton from '../TwiceCheckButton';

const defaultMap = [
  {label: '启用角色', nextLable: '确定启用', value: 0},
  {label: '停用角色', nextLable: '确定停用', value: 1},
];

/**
 * @typedef {Object} CustomButtonProps
 * @property {Number} value - 初始值
 * @property {Function=} canClickEnsure - 在二次身份验证通过后且在点击确认之前的判断条件回调函数，需返回boolean
 * @property {Boolean} twiceCheck - 是否需要二次身份验证
 * @property {Array} textMap - 按钮变化顺序数组
 * @property {Function=} permChangeFetcher - 权限变化请求
 * @property {Function=} permChangeValidator - 对permChangeFetcher返回数据过滤得到权限变化结果
 */

/**
 * @description: 按钮点击变换字体后再次确认
 * @param {CustomButtonProps & import('antd').ButtonProps} props
 * */
export const FlowTextButton = (props) => {
  const {
    value = 1,
    canClickEnsure,
    twiceCheck = false,
    textMap = defaultMap,
    onClick: originClick = (v) => v,
    ...restProp
  } = props;

  const [canNext, setCanNext] = useSafeState(true);
  const [loading, setLoading] = useSafeState(false);

  const currentFlow = textMap.find((t) => t.value === value);
  const anotherFlow = textMap.filter((t) => t.value !== value)[0];

  const {label, nextLable, tipCode = '', ...rest} = currentFlow;

  const [curShowText, setCurShowText] = useSafeState(currentFlow.label);

  const onClickHandle = async () => {
    if (curShowText === label) {
      // 添加在进行确认点击之前的接口调用判断操作(例见【数据库表管理_注释15】)
      if (canClickEnsure) {
        const canNextRes = await canClickEnsure();

        setCanNext(canNextRes);

        if (canNextRes) setCurShowText(nextLable);
      } else {
        setCurShowText(nextLable);
      }
    }

    if (curShowText === nextLable && canNext) {
      try {
        setLoading(true);
        await originClick(anotherFlow?.value);
      } finally {
        setLoading(false);
      }
    }
  };

  const mergeProps = {
    ...restProp,
    ...rest,
    ...(tipCode ? {href: ''} : {}),
  };

  return (
    <GlobalTooltip code={tipCode}>
      <TwiceCheckButton
        loading={loading}
        twiceCheck={twiceCheck}
        {...mergeProps}
        onClick={onClickHandle}
      >
        {curShowText}
      </TwiceCheckButton>
    </GlobalTooltip>
  );
};
