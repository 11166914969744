/* eslint-disable react/jsx-filename-extension */
import {ConfigProvider, notification} from 'antd';

import IconFont from '../components/IconFont';
import {FULLSCREEN_POINT} from '../constants/system';
import {themeColors} from '../constants/theme';

ConfigProvider.config({
  theme: themeColors,
});

notification.config({
  duration: 3,
  top: 60,
  placement: 'topRight',
  closeIcon: <IconFont style={{fontSize: '14px'}} type='icon-close' />,
  getContainer: () =>
    document.getElementById(FULLSCREEN_POINT) ?? document.body,
  maxCount: 5,
});

const originOpen = notification.open;

notification.open = (props) => {
  const {type} = props;

  const icon =
    type === 'success' ? (
      <IconFont
        style={{color: '#00A870', fontSize: '16px'}}
        type='icon-check-circle-filled'
      />
    ) : (
      <IconFont
        style={{color: '#E34D59', fontSize: '16px'}}
        type='icon-close-circle-filled'
      />
    );

  const mergeProps = {
    ...props,
    icon,
    message: null,
  };
  originOpen(mergeProps);
};
