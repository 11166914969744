import {css} from '@emotion/react';

export const normalLink = css`
  color: #fff;
  opacity: 0.75;
  transition: opacity 0.4s;
`;

export const hoverLink = css`
  opacity: 1;
`;

export const logoCSS = css`
  display: flex;
  align-items: center;
  justify-content: start;
  line-height: normal;
  /* margin-right: 30px; */
  /* cursor: pointer; */

  .ant-image {
    /* margin-right: 9px; */
  }

  .sysName {
    font-size: 18px;
    line-height: 25px;
    font-weight: 500;
    margin-left: 6px;
    color: white;
  }
`;

export const headerCSS = css`
  background-color: #16234e;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  height: 48px;
  font-weight: 400;

  .headerLeft {
    display: flex;
    align-items: center;
  }

  .collapseBtn {
    margin-right: 10px;
  }

  .fullScreenBtn {
    cursor: pointer;
  }

  .letterButton {
    margin-left: 16px;
    .icon-message,
    .text {
      ${normalLink}
    }
    &:hover {
      .icon-message,
      .text {
        ${hoverLink}
      }
    }
  }

  .breadcrumb {
    color: rgba(255, 255, 255, 0.5);
    .ant-breadcrumb-separator {
      color: rgba(255, 255, 255, 0.5);
    }
    & > span:last-child {
      color: rgba(255, 255, 255, 0.9);
    }
  }

  .icon svg {
    width: 100%;
    height: 100%;
  }
`;

export const userCSS = css`
  display: flex;
  line-height: 48px;
  align-items: center;
  font-size: 12px;

  .downloadDoc {
    display: flex;
    align-items: center;
    /* &::before {
      content: '';
      display: inline-block;
      width: 1px;
      height: 12px;
      opacity: 0.4 !important;
      background: #fff;
      margin-left: 14px;
      margin-right: 12px;
    } */
    .icon-doc {
      margin-right: 3px;
    }
  }

  .avatar {
    margin-right: 5px;
    vertical-align: top;
    width: 24px;
    height: 24px;
  }

  .name {
    display: inline-block;
    width: 60px;
    color: #fff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 24px;
  }

  .icon-dropdown {
    color: rgba(255, 255, 255, 0.5);
    vertical-align: top;
    margin: 5px 10px 0;
  }
`;

export const userInfoCss = css`
  padding: 3px 5px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  height: 30px;
  line-height: 1;
  margin-left: 18px;
  cursor: pointer;
`;

export const contentCSS = css`
  flex: 1;
`;
