/* eslint-disable no-empty-function */
import {css} from '@emotion/react';
import {Modal} from 'antd';
import {useAtom} from 'jotai';
import {useRef} from 'react';
import Draggable from 'react-draggable';

import {modalMovedAtom} from '../../atoms/commonAtom';
import useSafeState from '../../hooks/useSafeState';
import IconFont from '../IconFont';

/**
 * @param {{children: import("react").ReactNode} & import("antd").ModalProps} props
 */
export const GlobalModal = (props) => {
  const {children, className, title, ...rest} = props;
  const [disabled, setDisabled] = useSafeState(true);
  const [isMoving, setIsMoving] = useSafeState(false);
  const [bounds, setBounds] = useSafeState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);

  const [, setModalMovedAtom] = useAtom(modalMovedAtom);

  const onStart = (_event, uiData) => {
    const {clientWidth, clientHeight} = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();

    if (!targetRect) {
      return;
    }

    setIsMoving(true);
    setModalMovedAtom(false);

    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const onStop = () => {
    setIsMoving(false);
    setModalMovedAtom(true);
  };

  return (
    <Modal
      centered
      forceRender
      closeIcon={<IconFont type='icon-close' />}
      mask={false}
      maskClosable={false}
      modalRender={(modal) => (
        <Draggable
          bounds={bounds}
          disabled={disabled}
          onStart={(event, uiData) => onStart(event, uiData)}
          onStop={onStop}
        >
          <div
            css={css`
              .ant-modal-header {
                border-bottom: none;
                padding: 0;
              }
              .ant-modal-content {
                border: 1px solid #e5e5e5;
                opacity: ${isMoving ? 0.2 : 1};
                .ant-form-item:last-child {
                  margin: 0;
                }
              }
            `}
            ref={draggleRef}
          >
            {modal}
          </div>
        </Draggable>
      )}
      title={
        <div
          css={css`
            width: 100%;
            cursor: move;
            user-select: none;
            padding: 16px;
            padding-left: 24px;
            border: 1px dashed transparent;
            &:hover {
              border: 1px dashed rgb(77, 94, 255, 0.3);
              cursor: move;
            }
          `}
          onBlur={() => {}}
          onFocus={() => {}} // fix eslintjsx-a11y/mouse-events-have-key-events
          // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
          onMouseOut={() => {
            setDisabled(true);
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
        >
          {title}
        </div>
      }
      {...rest}
    >
      <div
        className={className}
        css={css`
          padding-bottom: 8px;
          user-select: none;
          &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 13, 26, 0.2);
            border-radius: 10px;
          }
          overflow-y: auto;
          max-height: 65vh;
        `}
      >
        {children}
      </div>
    </Modal>
  );
};

export default GlobalModal;
