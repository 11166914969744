import {BsArrowDown, BsArrowUp} from 'react-icons/bs';

import {REG_CN_WORD} from '../../constants/regExp';
import {ADMINCODE, BUTTON_PERMS_MAP} from '../../constants/system';
import {deepGet} from '../../helpers/utils';
import {AuthButton} from '../AuthButton';
import FilterColumn from './components/FilterColumn';

const MAX_COLUMN_WIDTH = 600;

/**  @param  {import('./type').CustomColumnType[]} colArr */
export const makeColumnTitle = (colArr, mutate, storeColumnWidthKey) => {
  const hasFilter = colArr.some((c) => c.filterType);
  return colArr.map((col) => {
    const {filterType = '', dataIndex, key} = col;

    return {
      ...col,
      title: filterType ? (
        // eslint-disable-next-line react/jsx-filename-extension
        <FilterColumn
          column={{onFilterSearch: mutate, ...col}}
          key={dataIndex ?? key}
          tableKey={storeColumnWidthKey}
        />
      ) : typeof col.title === 'function' ? (
        col.title()
      ) : hasFilter && typeof col.title === 'string' ? (
        () => <span style={{display: 'block', marginTop: 14}}>{col.title}</span>
      ) : (
        col.title
      ),
    };
  });
};

/**  @param  {import('./type').CustomColumnType[]} colArr */
export const calcColumns = (colArr) => {
  return colArr.map((col) => {
    const {align = 'left'} = col;

    return {
      ellipsis: true,
      sorter: false,
      sortOrder: false,
      showSorterTooltip: false,
      ...col,
      align,
    };
  });
};

export const computeSortIcon = ({
  reorderVal,
  dataSource,
  reorderKey,
  reorderBtnPermText,
  mutate,
  reorderCallBack,
}) => {
  let SortIcon = null;
  const colStyle = {
    display: 'flex',
    justifyContent: 'space-around',
  };
  const placeholderBox = {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  };

  const findPosition = (list = [], value) => {
    /**
     * reorderCallBack({
     *  direction; 方向，1：向上，2：向下
     *  item; 当前行数据
     * })
     **/

    for (let index = 0; index < list.length; index++) {
      const item = list[index];
      const children = item.children ?? [];
      const reorderKeyVal = item[reorderKey];
      const curLen = list.length;
      const hasCurValInList = list.some((v) => v[reorderKey] === value);

      if (hasCurValInList) {
        if (reorderKeyVal === value) {
          SortIcon =
            curLen > 1 ? (
              index === 0 ? (
                // eslint-disable-next-line react/jsx-filename-extension
                <div style={colStyle}>
                  <div style={placeholderBox}>
                    <AuthButton
                      icon={<BsArrowDown />}
                      permCode={BUTTON_PERMS_MAP.get(reorderBtnPermText)}
                      size='small'
                      onClick={() => reorderCallBack({direction: 2, item})}
                    />
                  </div>
                  <div style={placeholderBox} />
                </div>
              ) : index === curLen - 1 ? (
                <div style={colStyle}>
                  <div style={placeholderBox} />
                  <div style={placeholderBox}>
                    <AuthButton
                      ghost
                      icon={<BsArrowUp />}
                      permCode={BUTTON_PERMS_MAP.get(reorderBtnPermText)}
                      size='small'
                      type='primary'
                      onClick={() => reorderCallBack({direction: 1, item})}
                    />
                  </div>
                </div>
              ) : (
                <div style={colStyle}>
                  <div style={placeholderBox}>
                    <AuthButton
                      icon={<BsArrowDown />}
                      permCode={BUTTON_PERMS_MAP.get(reorderBtnPermText)}
                      size='small'
                      onClick={() => reorderCallBack({direction: 2, item})}
                    />
                  </div>
                  <div style={placeholderBox}>
                    <AuthButton
                      ghost
                      icon={<BsArrowUp />}
                      permCode={BUTTON_PERMS_MAP.get(reorderBtnPermText)}
                      size='small'
                      type='primary'
                      onClick={() => reorderCallBack({direction: 1, item})}
                    />
                  </div>
                </div>
              )
            ) : null;

          break;
        }
      } else if (children.length > 0) {
        findPosition(children, value);
      }
    }
  };
  if (reorderVal === ADMINCODE) {
    SortIcon = null;
  } else {
    findPosition(dataSource, reorderVal);
  }

  return SortIcon;
};

export const reorderColumns = ({
  columns,
  dataSource,
  reorderKey,
  reorderBtnPermText,
  reorderIndex,
  mutate,
  reorderCallBack,
  storeColumnWidthKey,
  reorderTitle,
}) => {
  const calcedColumns = calcColumns(columns, mutate, storeColumnWidthKey);
  const reorderCol = {
    title: reorderTitle,
    align: 'center',
    dataIndex: reorderKey,
    width: 100,
    fixWidth: true,
    render: (reorderVal) =>
      computeSortIcon({
        reorderVal,
        dataSource,
        reorderKey,
        reorderBtnPermText,
        mutate,
        reorderCallBack,
      }),
  };

  return [
    ...calcedColumns.slice(0, reorderIndex),
    reorderCol,
    ...calcedColumns.slice(reorderIndex),
  ];
};

export const handleResize = (callback, restHeight) => {
  const paddingValue = 16 * 2;

  const headRect = document.querySelector('.ant-layout-header');
  const pageTop = document.querySelector('.tablePageTopSection');
  const barRect = document.querySelector('.pageActionBar');
  const theadRect = document.querySelector('.ant-table-thead');
  const pagiRect = document.querySelector('.ant-pagination');
  const footerRect = document.querySelector('.system-footer');

  const {height: pageHeaderHeight} = headRect?.getBoundingClientRect() ?? {};
  const {height: pageTopHeight} = pageTop?.getBoundingClientRect() ?? {};
  const {height: actionBarHeight} = barRect?.getBoundingClientRect() ?? {};
  const {height: theadHeight} = theadRect?.getBoundingClientRect() ?? {};
  const {height: paginationHeight} = pagiRect?.getBoundingClientRect() ?? {};
  const {height: footerHeight} = footerRect?.getBoundingClientRect() ?? {};

  const otherHeight =
    (pageHeaderHeight ?? 0) +
    (pageTopHeight ?? 0) +
    (actionBarHeight ? actionBarHeight + 20 : 0) +
    (theadHeight ?? 0) +
    (paginationHeight ? paginationHeight + 32 : 0) +
    (footerHeight ?? 0) +
    paddingValue;

  callback(`calc(100vh - ${otherHeight + restHeight}px)`);
  // callback((pre) => ({...pre, y: `calc(100vh - ${otherHeight}px)`}));
};

// 表格数据不为空时列宽度自动补齐计算
export const colWidthFix = ({
  totalCols,
  dataSource,
  storeColumnWidthKey,
  fixedTableWidth,
}) => {
  const tableEl = document.querySelector(`.${storeColumnWidthKey}-tablesheet`);

  /** 表格可视区域宽度 */
  const tableWidth = tableEl?.clientWidth ?? 0;

  /** 已动态分配的宽度之和 */
  let widths = 0;

  /** 所有动态分配列宽的索引集合 */
  const allWidthIndex = [];

  const finalCols = totalCols.map((col, i) => {
    // 只为不固定宽度的列动态分配宽度
    if (!col.fixWidth) {
      allWidthIndex.push(i);
    }
    if (col.width) {
      widths += col.width;
    } else {
      const curColValArr = dataSource
        .map((dataRow) => deepGet(dataRow, col.dataIndex ?? '', ''))
        .sort((a, b) => String(a).length - String(b).length);

      const maxLengthVal = String(curColValArr.at(curColValArr.length - 1));
      const maxLenColValLen = maxLengthVal.length;

      let w =
        (REG_CN_WORD.test(maxLengthVal)
          ? maxLenColValLen * 12
          : maxLenColValLen * 6) + 20;
      w = w > MAX_COLUMN_WIDTH ? MAX_COLUMN_WIDTH : w;
      const titleW =
        typeof col.title === 'string'
          ? col.title.length * 18 + 20
          : col.titleWidth;

      w = w < titleW ? titleW : w;

      widths += w;

      return {
        ...col,
        width: w,
      };
    }
    return col;
  });

  if (widths < tableWidth) {
    const perWidth = Math.ceil((tableWidth - widths) / allWidthIndex.length);
    allWidthIndex.forEach((item, index) => {
      if (index < allWidthIndex.length - 1) {
        finalCols[item].width += perWidth;
        widths += perWidth;
      } else {
        finalCols[item].width += tableWidth - widths;
        widths += tableWidth - widths;
      }
    });
  } else if (widths > tableWidth && fixedTableWidth) {
    const remainingWidths = widths - tableWidth;
    const perWidth = Math.ceil(remainingWidths / allWidthIndex.length);
    allWidthIndex.forEach((item, index) => {
      const col = finalCols[item];
      const columnMinWidth =
        typeof col.title === 'string'
          ? col.title.length * 18 + 20
          : col.titleWidth;
      const maxWidth =
        col.width - perWidth <= columnMinWidth
          ? col.width - columnMinWidth
          : perWidth;
      col.width -= maxWidth;
      widths -= maxWidth;
      let i = 0;
      // 当遍历完全部时宽度仍然超出范围
      // 再次进行遍历
      while (
        index === allWidthIndex.length - 1 &&
        i <= allWidthIndex.length - 1 &&
        widths > tableWidth
      ) {
        // 当前遍历到的动态宽度列在所有列中的索引位置
        const curIndex = allWidthIndex[i];
        // 超出可见范围的总宽度
        const finalRemainingWidths = widths - tableWidth;
        const curCol = finalCols[curIndex];
        const curColumnMinWidth =
          typeof curCol.title === 'string'
            ? curCol.title.length * 18 + 20
            : curCol.titleWidth;
        const finalMaxWidth =
          curCol.width - finalRemainingWidths <= curColumnMinWidth
            ? curCol.width - curColumnMinWidth
            : finalRemainingWidths;
        curCol.width -= finalMaxWidth;
        widths -= finalMaxWidth;
        i++;
      }
    });
  }
  return finalCols;
};

// 表格数据为空时计算列宽
export function makeAverageWidth(
  columns,
  storeColumnWidthKey,
  fixedTableWidth
) {
  const tableEl = document.querySelector(`.${storeColumnWidthKey}-tablesheet`);

  /** 表格可视区域宽度 */
  const tableWidth = tableEl?.clientWidth ?? 0;

  /** 已动态分配的宽度之和 */
  let widths = 0;

  /** 所有动态分配列宽的索引集合 */
  const allWidthIndex = [];

  const finalCols = columns.map((tcol, i) => {
    // 只为不固定宽度的列动态分配宽度
    if (!tcol.fixWidth) {
      allWidthIndex.push(i);
    }
    if (tcol.width) {
      widths += tcol.width;
    } else {
      const w =
        typeof tcol.title === 'string'
          ? tcol.title.length * 18 + 20
          : tcol.titleWidth;

      widths += w;

      return {
        ...tcol,
        width: w,
      };
    }
    return tcol;
  });

  if (widths < tableWidth) {
    const perWidth = Math.ceil((tableWidth - widths) / allWidthIndex.length);
    allWidthIndex.forEach((item, index) => {
      if (index < allWidthIndex.length - 1) {
        finalCols[item].width += perWidth;
        widths += perWidth;
      } else {
        finalCols[item].width += tableWidth - widths;
        widths += tableWidth - widths;
      }
    });
  } else if (widths > tableWidth && fixedTableWidth) {
    const remainingWidths = widths - tableWidth;
    const perWidth = Math.ceil(remainingWidths / allWidthIndex.length);
    allWidthIndex.forEach((item, index) => {
      const col = finalCols[item];
      const columnMinWidth =
        typeof col.title === 'string'
          ? col.title.length * 18 + 20
          : col.titleWidth;
      const maxWidth =
        col.width - perWidth <= columnMinWidth
          ? col.width - columnMinWidth
          : perWidth;
      col.width -= maxWidth;
      widths -= maxWidth;
      let i = 0;
      while (
        index === allWidthIndex.length - 1 &&
        i <= allWidthIndex.length - 1 &&
        widths > tableWidth
      ) {
        const curIndex = allWidthIndex[i];
        const finalRemainingWidths = widths - tableWidth;
        const curCol = finalCols[curIndex];
        const curColumnMinWidth =
          typeof curCol.title === 'string'
            ? curCol.title.length * 18 + 20
            : curCol.titleWidth;
        const finalMaxWidth =
          curCol.width - finalRemainingWidths <= curColumnMinWidth
            ? curCol.width - curColumnMinWidth
            : finalRemainingWidths;
        curCol.width -= finalMaxWidth;
        widths -= finalMaxWidth;
        i++;
      }
    });
  }

  return finalCols;
}

export const setColumnsOnMount =
  (dataSource, columnMinWidth, storeColumnWidthKey, totalCols) => () => {
    if (dataSource.length > 0) {
      return colWidthFix({
        totalCols,
        columnMinWidth,
        dataSource,
        storeColumnWidthKey,
      });
    }
    return makeAverageWidth(totalCols, storeColumnWidthKey);
  };

export const setColumnsOnResizeEnd =
  (storeColumnWidthKey, fixedTableWidth, columnMinWidth) =>
  (totalCols, id, afterColWidth) => {
    const tableEl = document.querySelector(
      `.${storeColumnWidthKey}-tablesheet`
    );

    // const curCol = totalCols.find((col) => col.dataIndex === id);
    // columnMinWidth = curCol.title.length * 18 + 20;

    /** 表格可视区域宽度 */
    const tableWidth = tableEl?.clientWidth ?? 0;

    const index = totalCols.findIndex((col) => col.dataIndex === id);

    const nextFixCol = totalCols[index + 1]?.fixWidth
      ? totalCols[index + 1]
      : null;

    const widths = totalCols.reduce((prev, cur) => {
      if (cur.dataIndex === id) {
        return prev + afterColWidth;
      }
      return prev + cur.width;
    }, 0);
    const finalCols = totalCols.map((col, i) => {
      if (!fixedTableWidth && nextFixCol && i === index) {
        if (widths > tableWidth) {
          tableEl
            .querySelectorAll('th.ant-table-cell')
            [i].querySelector(
              '.resizable-box'
            ).style.width = `${afterColWidth}px`;
          return {
            ...col,
            width: afterColWidth,
          };
        }
        tableEl
          .querySelectorAll('th.ant-table-cell')
          [i].querySelector('.resizable-box').style.width = `${
          tableWidth - (widths - afterColWidth)
        }px`;
        return {
          ...col,
          width: tableWidth - (widths - afterColWidth),
        };
      }
      if ((widths < tableWidth || !fixedTableWidth) && i === index) {
        return {
          ...col,
          width: afterColWidth,
        };
      } else if (widths < tableWidth && i === index + 1 && !col.fixWidth) {
        return {
          ...col,
          width: col.width + tableWidth - widths,
        };
      }
      if (fixedTableWidth) {
        if (widths > tableWidth && i === index) {
          const diff = widths - tableWidth; // 拖拽后增加的差值
          const rawNextColWidth = totalCols[i + 1].width;
          const nextColMinWidth =
            typeof totalCols[i + 1].title === 'string'
              ? totalCols[i + 1].title.length * 18 + 20
              : totalCols[i + 1].titleWidth;
          const nextColWidth =
            rawNextColWidth - diff < nextColMinWidth
              ? nextColMinWidth
              : rawNextColWidth - diff;
          const newWidth = widths - rawNextColWidth + nextColWidth;
          if (newWidth > tableWidth) {
            tableEl
              .querySelectorAll('th.ant-table-cell')
              [i].querySelector('.resizable-box').style.width = `${
              afterColWidth - (newWidth - tableWidth)
            }px`;
            return {
              ...col,
              width: afterColWidth - (newWidth - tableWidth),
            };
          }
          return {
            ...col,
            width: afterColWidth,
          };
        } else if (widths > tableWidth && i === index + 1 && !col.fixWidth) {
          const diff = widths - tableWidth; // 拖拽后增加的差值
          const nextColMinWidth =
            typeof totalCols[i].title === 'string'
              ? totalCols[i].title.length * 18 + 20
              : totalCols[i].titleWidth;
          const nextColWidth =
            col.width - diff < nextColMinWidth
              ? nextColMinWidth
              : col.width - diff;
          return {
            ...col,
            width: nextColWidth,
          };
        }
      }

      return col;
    });

    return finalCols;
  };
