import {css} from '@emotion/react';
import {Button, Statistic} from 'antd';
import {useAtom} from 'jotai';
import React from 'react';

import {permChangeAtom} from '../../../atoms/permChangeAtom';
import {GlobalModal} from '../../../components/GlobalModal';
import {selfInfoPath} from '../../../constants/system';
import {routeHistory} from '../../../helpers/history';
import useAuthMenuTree from '../../../hooks/useAuthMenuTree';

export function PermChangeModal(props) {
  const [isPermChange, setIsPermChange] = useAtom(permChangeAtom);
  const [, freshAuthMenu] = useAuthMenuTree();

  function handleJump() {
    routeHistory.replace(selfInfoPath);
    freshAuthMenu();
    setIsPermChange(false);
  }

  if (!isPermChange) return null;

  return (
    <GlobalModal
      visible
      closable={false}
      footer={
        <Button
          css={css`
            align-items: center;
            display: flex;
            gap: 2px;
            margin: 0 auto;
          `}
          type='primary'
          onClick={handleJump}
        >
          跳转 (
          <Statistic.Countdown
            format='s'
            value={Date.now() + 5 * 1000}
            valueStyle={{color: '#fff', fontSize: '14px'}}
            onFinish={handleJump}
          />
          )
        </Button>
      }
      keyboard={false}
      maskClosable={false}
      title='提示'
      width={350}
      {...props}
    >
      <p>您的权限已发生改变，即将跳转到个人中心页面</p>
    </GlobalModal>
  );
}
