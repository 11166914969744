export function flattenMenus(menus) {
  return menus.reduce((prev, {children = [], ...cur}) => {
    if (children?.length > 0) {
      return prev.concat(cur).concat(flattenMenus(children));
    }
    return prev.concat(cur);
  }, []);
}

/**
 * 将菜单转化为下面的形式
 * 1: "自动采集"
 * 1/2: "自动采集/采集任务"
 * 1/2/3: "自动采集/采集任务/子页面名称"
 */
export function formatBreadcrumb(menus) {
  function recursive(arr, parentName = '') {
    return Array.isArray(arr) && arr.length > 0
      ? arr.reduce((pre, cur) => {
          const name = cur.name ?? '';
          const code = cur.code ?? '';
          const type = cur.nodeInfo?.type ?? '';
          const routeUrl = cur.nodeInfo?.routeUrl ?? '';
          const children = cur.children ?? [];

          // 适配下钻页：面包屑
          const childPageCode = routeUrl?.split('/')?.at(-1);

          const curName = `${parentName ? `${parentName}/` : ''}${name}`;
          const curPathTitleObj = {
            [type === 2 ? `${code}-${childPageCode}` : code]: curName,
          };

          pre = {
            ...pre,
            ...curPathTitleObj,
            ...(children.length > 0 ? recursive(children, curName) : {}),
          };
          return pre;
        }, {})
      : {};
  }
  const breadcrumbObject = recursive(menus);
  return breadcrumbObject;
}

export const findOpenKeys = (list, value) => {
  const result = [];

  const find = (arr, val) => {
    const curItem = list.find((v) => v.code === val);
    const {parentCode} = curItem;
    if (parentCode !== '-1') {
      result.unshift(parentCode);
      find(arr, parentCode);
    }
  };

  if (list.length > 0 && value) {
    find(list, value);
  }

  return result;
};
