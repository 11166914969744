import {css} from '@emotion/react';
import {useAtom} from 'jotai';
import React, {useEffect} from 'react';

import {fetchSysSettingAtom} from '../../atoms/sysSettingAtom';

const copyRight = css`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 12px;
  text-align: center;
`;
const SysFooter = () => {
  const [sysSettingDetail, freshIndexSysSetting] = useAtom(fetchSysSettingAtom);

  useEffect(() => {
    freshIndexSysSetting();
  }, [freshIndexSysSetting]);

  return (
    <div className='system-footer' css={copyRight}>
      <div className='copy-area'>{sysSettingDetail.copyright}</div>
      <div className='support'>{sysSettingDetail.support}</div>
    </div>
  );
};

export default SysFooter;
