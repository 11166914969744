import Watermark from '@uiw/react-watermark';
import {useAtom} from 'jotai';
import React from 'react';

import {fetchSysSettingAtom} from '../../atoms/sysSettingAtom';

const WaterMarks = ({children}) => {
  const [sysSettingDetail] = useAtom(fetchSysSettingAtom);
  const sysName = sysSettingDetail?.name;
  const sysNameWidth = sysName.length * 20;

  return (
    <Watermark
      content={sysName}
      fontColor='rgba(0,0,0,.12)'
      gapX={sysNameWidth - sysNameWidth / 3}
      gapY={sysNameWidth}
      rotate={-30}
      style={{background: '#fff'}}
    >
      {children}
    </Watermark>
  );
};

export default WaterMarks;
