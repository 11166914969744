import {css} from '@emotion/react';
import React from 'react';
import {useRef} from 'react';
import ReactDOM from 'react-dom';
import {AiFillQuestionCircle} from 'react-icons/ai';

import {findElementByKey} from '../../helpers/utils';
import useSafeState from '../../hooks/useSafeState';
import {useTooltip} from './useTooltip';

const toolTipWrapperCss = css`
  display: inline-block;
  position: relative;
  &:empty {
    display: none;
  }
`;

const questionIconCss = css`
  color: #000 !important;
  cursor: pointer;
  position: absolute;
  width: 16px !important;
  height: 16px !important;
`;

const tipContentCss = css`
  &.left::before {
    content: '';
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-left: 6px solid rgba(0, 0, 0, 0.6);
    border-bottom: 4px solid transparent;
    position: absolute;
    right: -6px;
    top: 5px;
  }
  &.right::before {
    content: '';
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-right: 6px solid rgba(0, 0, 0, 0.6);
    border-bottom: 4px solid transparent;
    position: absolute;
    left: -6px;
    top: 5px;
  }
  color: #fff;
  position: absolute;
  font-size: 14px;
  padding: 0 4px;
  background: rgba(0, 0, 0, 0.6);
  height: auto !important;
`;

/**
 * @param {object} props
 * @param {import("react").ReactNode} props.children 默认展示的元素
 * @param {string} props.code 提示code
 */
export function GlobalTooltip({children, code, className, ...rest}) {
  const [contentVisible, setContentVisible] = useSafeState(false);
  const [iconVisible, setIconVisible] = useSafeState(false);
  const [tipsPosition, setTipsPosition] = useSafeState({
    left: 0,
    right: 0,
    top: 0,
  });
  const wrapperRef = useRef();
  const tipContentRef = useRef();

  const docWidth = document.documentElement.clientWidth;

  const getBoundingClientRect4Wrapper =
    wrapperRef.current?.getBoundingClientRect();

  const currentPageTooltip = useTooltip();

  const tips = findElementByKey(currentPageTooltip, code, 'code')?.value;

  const onTriggerTip = (e) => {
    e.stopPropagation();
    if (contentVisible) return;
    const getBoundingClientRect4Content =
      tipContentRef.current?.getBoundingClientRect();
    const overflow =
      getBoundingClientRect4Wrapper?.left +
        getBoundingClientRect4Content.width >
      docWidth;
    setTipsPosition({
      left: overflow ? 'auto' : e.clientX + 16,
      right: overflow ? docWidth - e.clientX + 16 : 'auto',
      top: e.clientY - 8,
    });
    setContentVisible(true);
    setTimeout(() => {
      setContentVisible(false);
    }, 5000);
  };

  const tipsNode = tips ? (
    <>
      {ReactDOM.createPortal(
        <AiFillQuestionCircle
          className='icon'
          css={questionIconCss}
          size={16}
          style={{
            display: iconVisible ? 'block' : 'none',
            zIndex: iconVisible ? 9999 : -1,
            left:
              getBoundingClientRect4Wrapper?.left +
                getBoundingClientRect4Wrapper?.width -
                5 || 0,
            top: getBoundingClientRect4Wrapper?.top - 10 || 0,
          }}
          onClick={onTriggerTip}
        />,
        document.getElementById('root')
      )}
      {ReactDOM.createPortal(
        <span
          className={tipsPosition.right === 'auto' ? 'right' : 'left'}
          css={tipContentCss}
          ref={tipContentRef}
          style={{
            visibility: contentVisible ? 'visible' : 'hidden',
            zIndex: contentVisible ? 9999 : -1,
            left: tipsPosition.left,
            right: tipsPosition.right,
            top: tipsPosition.top,
            maxWidth: `${docWidth / 2}px`,
          }}
        >
          {tips}
        </span>,
        document.getElementById('root')
      )}
    </>
  ) : null;

  return (
    <span
      className={className}
      css={toolTipWrapperCss}
      ref={wrapperRef}
      onMouseEnter={() => {
        setIconVisible(true);
      }}
      onMouseLeave={() => {
        setIconVisible(false);
      }}
    >
      {typeof children === 'string'
        ? children
        : typeof children === 'function'
        ? children(rest)
        : React.Children.map(children, (child) => {
            return {
              ...child,
              props: {...child.props, ...rest},
            };
          })}
      {tipsNode}
    </span>
  );
}
