import React, {useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';

import {storageKeys} from '../../constants/storageKeys';

// import {useToken} from '../../hooks/useToken';

export default function Detail() {
  const [searchParams] = useSearchParams();
  const frameUrl = decodeURIComponent(searchParams.get('link'));

  useEffect(() => {
    const iframe = document.getElementById('adaptiveFrameDetail');

    iframe.onload = () => {
      const postData = window.localStorage.getItem(storageKeys.TOKEN);
      iframe.contentWindow.postMessage(
        {
          token: postData,
          type: 'stxz',
        },
        '*'
      );
    };
  }, []);

  return (
    <iframe
      frameBorder={0}
      id='adaptiveFrameDetail'
      src={frameUrl}
      style={{
        width: '100%',
        height: '100%',
      }}
    />
  );
}
