import {css} from '@emotion/react';
import {Card, Select} from 'antd';
import React, {useEffect} from 'react';
import useSWR from 'swr';

import {GET_USER_PERM} from '../../../../api/system/accountManage';
import {GET_APP_LIST} from '../../../../api/system/menuManage';
import StatusItem from '../../../../components/StatusItem';
import TableSheetCopy from '../../../../components/TableSheetCopy';
import useSafeState from '../../../../hooks/useSafeState';
import {cardBodyStyle, cardHeadStyle, PermListTitle} from './CardTitles';

const ORGANIZATION_STATUS_MAP = new Map([
  [1, '全部组织'],
  [2, '所属组织及子组织'],
  [3, '所属组织'],
  [4, '自己'],
]);

const AREA_STATUS_MAP = new Map([
  [1, '全部区划'],
  [2, '所属区划及子区划'],
  [3, '所属区划'],
]);

const AccountPermTable = ({userInfo = {}}) => {
  const [currentSystem, setCurrentSystem] = useSafeState(null);

  /** 系统列表 */
  const {data: appListData} = useSWR(GET_APP_LIST);

  useEffect(() => {
    if (appListData?.data?.length > 0) {
      setCurrentSystem(appListData.data[0].id);
    }
  }, [appListData, setCurrentSystem]);

  /** @type {import('antd/lib/table').ColumnsType} */
  const columns = [
    {
      dataIndex: 'menuName',
      title: '页面',
    },
    {
      dataIndex: 'hasPerm',
      title: '页面权限',
      render: (value) => {
        return <StatusItem.Icon status={value === 1 ? 'success' : 'error'} />;
      },
    },
    {
      dataIndex: 'dataType',
      title: '区划权限',
      render: (value, row) => {
        if (value === 1) {
          return <StatusItem.Icon status='error' />;
        }
        return row.dataPerm
          ? AREA_STATUS_MAP.get(row.dataPerm.permStatus)
          : '--';
      },
    },
    {
      dataIndex: 'dataType',
      title: '组织权限',
      render: (value, row) => {
        if (value === 2) {
          return <StatusItem.Icon status='error' />;
        }
        return row.dataPerm
          ? ORGANIZATION_STATUS_MAP.get(row.dataPerm.permStatus)
          : '--';
      },
    },
    {
      dataIndex: 'funcPerms',
      title: '功能权限',
      render: (value) => {
        return value?.length > 0 ? (
          <StatusItem.TagsWrapper>
            <StatusItem.Tags
              dataMapping={(item, index) => ({
                label: item.menuName,
                status: item.hasPerm === 1 ? 'success' : 'error',
                key: index,
              })}
              list={value}
            />
          </StatusItem.TagsWrapper>
        ) : (
          '--'
        );
      },
    },
  ];

  const {data} = userInfo;

  return (
    <Card
      css={css`
        ${cardHeadStyle}
        ${cardBodyStyle}
        .ant-card-head-title {
          width: 100%;
        }
      `}
      title={
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
          `}
        >
          <PermListTitle />
          <Select
            css={css`
              width: 106px;
            `}
            fieldNames={{
              label: 'name',
              value: 'id',
            }}
            options={appListData?.data ?? []}
            size='small'
            value={currentSystem}
            onChange={(v) => {
              setCurrentSystem(v);
            }}
          />
        </div>
      }
    >
      <TableSheetCopy
        clearFilter={false}
        columns={columns}
        dataApi={data?.userId && currentSystem ? GET_USER_PERM : null}
        // fetcher={getUserCenterPermission}
        extraParams={{
          appId: currentSystem,
          userId: data?.userId,
        }}
        pagination={false}
        refresh={false}
        rowKey='menuCode'
        scroll={{y: '300px'}}
        storeColumnWidthKey='AccountPermTable'
      />
    </Card>
  );
};

export default AccountPermTable;
