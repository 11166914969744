import {atom} from 'jotai';

// 微观指标状态store
const logicEditAtom = atom({
  // 当前选中的数据实例、数据库、数据表
  curInstance: {},
  curDatabase: {},
  curDataSheet: {},

  // 逻辑编辑预览表横向X，竖向Y
  previewTableHeight: 260,
  previewTableDirection: 'X',

  // 显示隐藏字段
  showHideFields: false,

  // 预览自动更新
  previewAutoUpdate: true,

  // 横向表列和数据
  previewBlingStyle: null,
  previewXData: [],
  previewXOriginColumns: [],

  // 纵向表列和数据
  previewYData: [],
  previewYOriginColumns: [],

  // 保存指标的文件夹和表名
  holderAnimateStyle: null,
  foldId: null,
  sheetName: null,
  sheetNameErrMsg: '',

  // 数据逻辑筛选总览表格弹窗
  filterPreviewModalVisible: false,

  // 表逻辑配置区，画布相关数据和状态
  graphData: {},
  graphOriginData: {},
  graphInstance: null,
  logicSetModalVisible: false,
});

export {logicEditAtom};
