import {css} from '@emotion/react';
import {useAtom} from 'jotai';
import React, {useMemo} from 'react';

import {logicEditAtom} from '../../../atoms/logicEditAtom';
import {textOverFlow} from '../../../global.css';
import useSafeState from '../../../hooks/useSafeState';
import {TableCellInput} from '../../TextHoverToInput';
import FilterButton from './FilterButton';
import {repeatTitleStyle} from './styles.css';

/**
 * @param {object} props
 * @param {import('../type').CustomColumnType} props.column
 * @param {string} props.tableKey
 */
export default function FilterColumn(props) {
  const {column, tableKey} = props;
  const {
    title,
    colCss = '',
    dataIndex,
    toolTip,
    _hasRepeat,
    filterType,
  } = column;

  const [titleEditing, setTitleEditing] = useSafeState(false);

  const showFilter = useMemo(() => {
    return filterType !== 'Sort';
  }, [filterType]);

  const [totalStatus, setTotalStatus] = useAtom(logicEditAtom);
  const {previewXOriginColumns, previewBlingStyle} = totalStatus;

  const onCancelEditTitle = () => {
    setTitleEditing(false);
  };

  const onSaveEditTitle = (newVal) => {
    const newColumns = previewXOriginColumns.map((col) => {
      if (col.dataIndex === dataIndex) {
        col.title = newVal;
      }

      const repeatTitleCol = previewXOriginColumns.find(
        (ocol) => ocol._title === newVal
      );

      if (repeatTitleCol) {
        if (
          col.dataIndex === dataIndex ||
          col.dataIndex === repeatTitleCol.dataIndex
        ) {
          col.colCss = css`
            ${col.colCss};
            ${repeatTitleStyle};
          `;

          col._hasRepeat = true;
        }
      }
      return col;
    });
    setTitleEditing(false);
    setTotalStatus((pre) => ({
      ...pre,
      previewXOriginColumns: newColumns,
    }));
  };

  return (
    <div
      css={css`
        ${colCss}
        ${_hasRepeat ? previewBlingStyle : null}
      `}
    >
      <FilterButton
        column={column}
        filterTableKey={tableKey}
        setTitleEditing={setTitleEditing}
        showFilter={showFilter}
      />

      <div
        css={css`
          ${textOverFlow}
          margin-top:  ${column?.extraFilterItems?.length > 0 ? 0 : '2px'};
          line-height: 14px;
          color: #1a2230;
        `}
      >
        {titleEditing ? (
          <TableCellInput
            defaultValue={title}
            size='small'
            onCancel={onCancelEditTitle}
            onSave={onSaveEditTitle}
          />
        ) : toolTip ? (
          toolTip(column)
        ) : (
          title
        )}
      </div>
    </div>
  );
}
