import {css} from '@emotion/react';
import {normalize} from 'polished';

import {theme, themeColors} from './constants/theme';
import {isElectron} from './helpers/isElectron';

// override basic style
export const rebase = css`
  ${normalize()}

  html {
    line-height: 1.4;
    overflow: overlay;
    box-sizing: border-box;
  }

  body {
    font-size: 14px;
    font-family: PingFangSC, -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
      sans-serif;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  button,
  a {
    color: currentColor;
    text-decoration: none;
    border: none;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
    &:active {
      opacity: 0.4;
    }
  }

  ::-webkit-input-placeholder {
    /* WebKit browsers，webkit内核浏览器 */
    color: rgba(26, 34, 48, 0.4);
    font-size: 14px;
  }
  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: rgba(26, 34, 48, 0.4);
    font-size: 14px;
  }
  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: rgba(26, 34, 48, 0.4);
    font-size: 14px;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: rgba(26, 34, 48, 0.4);
    font-size: 14px;
  }
  .luckysheet-modal-dialog-buttons {
    text-align: right;
  }
`;

export const overflowScrollBar = css`
  ::-webkit-scrollbar {
    width: ${theme.scrollbar.WIDTH}px;
    height: ${theme.scrollbar.WIDTH}px;
  }

  ::-webkit-scrollbar-track {
    margin: 2px 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${theme.scrollbar.COLOR};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${theme.scrollbar.HOVER_COLOR};
  }
`;

// global layout style
export const layout = css`
  html,
  body,
  #root,
  #root > * {
    height: 100%;
  }
  .clearfix::after {
    content: '';
    width: 0;
    height: 0;
    clear: both;
  }
`;

// global theming style
export const theming = css`
  html,
  body {
    color: ${theme.colors.TEXT};
    background-color: ${theme.colors.BACKGROUND};
  }
  .icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
  }
`;

// override ant design style
export const overrideAntd = css`
  .ant-btn-icon-only {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-table-body,
  .ant-tree-list-holder,
  .ant-card-body {
    ::-webkit-scrollbar {
      width: ${theme.scrollbar.WIDTH}px;
      height: ${theme.scrollbar.WIDTH}px;
    }

    ::-webkit-scrollbar-track {
      margin: 2px 0;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${theme.scrollbar.COLOR};
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: ${theme.scrollbar.HOVER_COLOR};
    }
  }

  th.ant-table-cell {
    user-select: none;
    color: #1a2230;
  }
  td.ant-table-cell {
    padding: 5px 12px !important;
    color: rgba(26, 34, 48, 0.7);
  }

  .ant-table-body {
    overflow-y: auto !important;
    &::after {
      content: '';
      border-bottom: 1px solid #f0f0f0;
      position: absolute;
      left: 0;
      bottom: -1px;
      right: 6px;
    }
    .ant-table-tbody > tr:last-child > td {
      border-bottom: none;
    }
  }

  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    z-index: 5;
  }

  .timingLoadingMsg {
    .ant-message-custom-content {
      display: flex;
      align-items: center;
    }
  }

  .ant-notification-notice-success {
    background: rgba(237, 255, 243, 1);
  }

  .ant-notification-notice-error {
    background: rgba(255, 239, 230, 1);
    color: #d9001b;
  }

  .ant-notification-notice-close {
    top: 5px;
    right: 10px;
  }

  // hack for antd's bug when menu collapsed
  .hidePopupMenu {
    .ant-menu-submenu-popup {
      z-index: -999 !important;
    }
  }

  // 数据管理弹窗按钮居中 hack
  .footerBtnCenterModal {
    .ant-modal-confirm-btns {
      float: none !important;
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
    }
  }

  .ant-menu-submenu-popup {
    background: #fff;
    .ant-menu-item-only-child {
      padding-left: 26px;
      color: ${theme.colors.MENU_COLOR};
    }
  }

  .ant-menu-submenu-popup > .ant-menu {
    background-color: rgba(37, 49, 69, 0.15);
  }

  .ant-menu-item {
    a:hover {
      color: ${theme.colors.MENU_COLOR};
    }
    &:hover {
      background-color: rgba(37, 49, 69, 0.1);
      border-radius: 2px;
    }
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #4d5eff;
    color: #ffffff;
    border-radius: 2px;
    a {
      color: #ffffff;
    }
  }

  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-table-small .ant-table-thead > tr > th {
    background-color: #f1f2f5;
  }

  .ant-pagination,
  .ant-table-pagination.ant-pagination {
    margin: 16px 0 0;
  }

  .ant-pagination-item {
    border: 1px solid #dcdcdc;
    a {
      color: rgba(26, 34, 48, 0.6);
    }
  }
  .ant-pagination-item-active {
    background: #4d5eff;
    a {
      color: rgba(255, 255, 255, 0.9);
      &:hover {
        color: #ffffff;
      }
    }
  }

  .ant-pagination-next .ant-pagination-item-link,
  .ant-pagination-prev .ant-pagination-item-link {
    border: none;
    font-size: 16px;
    .anticon {
      color: rgba(26, 34, 48, 0.6);
    }
  }
  .ant-pagination-total-text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(26, 34, 48, 0.4);
  }

  .ant-pagination-options-quick-jumper {
    color: rgba(26, 34, 48, 0.6);
    font-size: 14px;
  }

  .ant-input-password-icon {
    color: rgba(26, 34, 48, 0.25);
    &:hover {
      color: rgba(26, 34, 48, 0.4);
    }
  }

  .ant-btn-primary:hover {
    background: #6675ff;
  }

  .ant-btn-primary:focus {
    background: #3f51ff;
  }

  .ant-btn[disabled]:not(.ant-btn-text) {
    color: rgba(26, 34, 48, 0.4);
    background: rgba(26, 34, 48, 0.05);
    border: 1px solid rgba(26, 34, 48, 0.06);
  }

  .ant-input-affix-wrapper {
    input {
      color: #1a2230;
    }
  }

  .ant-modal-wrap {
    overflow: hidden;
  }
  .ant-modal-header {
    border-bottom: none;
    padding: 16px 20px;
  }
  .ant-modal-title {
    font-size: 16px;
    font-weight: 500;
  }
  .ant-modal-content {
    border-radius: 4px;
  }
  .ant-modal-body {
    padding-top: 0px;
    padding-bottom: 12px;
  }

  .ant-modal-footer {
    border: none;
    padding-top: 12px;
    padding-bottom: 24px;
  }

  .ant-btn-primary {
    text-shadow: none;
    box-shadow: none;
  }
  .ant-btn {
    box-shadow: none;
  }

  // select样式
  .ant-select-item-option-content {
    color: ${theme.colors.FONT_COLOR};
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background: rgba(203, 203, 203, 0.15);
    .ant-select-item-option-content {
      color: ${theme.colors.FONT_COLOR};
    }
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: ${theme.colors.SELECTED_BACKGROUND};
    .ant-select-item-option-content {
      color: ${themeColors.primaryColor};
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid rgba(26, 34, 48, 0.15);
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-weight: 400;
  }
  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: #1a2230;
  }
  .ant-select-item-group {
    color: #1a2230;
    font-weight: 500;
    font-size: 14px;
  }
  .ant-select-item-option-grouped {
    padding-left: 12px;
    .ant-select-item-option-content {
      color: rgba(26, 34, 48, 0.6) !important;
    }
  }
  .rc-virtual-list-scrollbar {
    width: ${theme.scrollbar.WIDTH}px !important;
  }
  .rc-virtual-list-scrollbar-thumb {
    background: #c6cfff !important;
  }

  .ant-checkbox + span {
    color: rgba(26, 34, 48, 0.7);
  }

  .ant-checkbox-disabled + span {
    color: rgba(26, 34, 48, 0.4);
  }

  .ensure-btn-text-color {
    color: rgba(255, 255, 255, 0.9) !important;
  }

  .cancel-btn-text-color {
    color: rgba(26, 34, 48, 0.6) !important;
  }

  .ant-form-item-label > label {
    color: rgba(26, 34, 48, 0.7);
  }

  .ant-form-item {
    margin-bottom: 16px;
  }

  .twiceCheckModal {
    .ant-modal-body {
      padding-top: 22px;
      padding-left: 23px;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
  }
  .no-padding-atd-btn {
    padding: 0;
  }

  // input样式
  /* .ant-input {
    padding: 2px 11px;
  } */
  .ant-input-affix-wrapper {
    border: 1px solid rgba(26, 34, 48, 0.15);
    color: #1a2230;
    input::input-placeholder {
      color: rgba(26, 34, 48, 0.4);
    }
    /* padding: 2px 11px; */
  }
  /* .ant-input:focus, */
  .ant-input-focused,
  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:focus {
    box-shadow: none;
    /* border: 1px solid #4d5eff; */
  }
  .ant-input-affix-wrapper-disabled {
    background: rgba(26, 34, 48, 0.05);
    border: 1px solid rgba(26, 34, 48, 0.06);
  }
  /* .ant-form-item-control-input {
    min-height: 28px;
  }
  .ant-form-item-label > label {
    height: 28px;
  }
  .ant-btn {
    height: 28px;
    line-height: 1.3715;
  }
  .ant-table-cell {
    .ant-btn {
      height: 24px;
    }
  } */
`;

// electron only
export const electronOnly =
  isElectron &&
  css`
    /* 绘制顶部拖拽区域 */
    body::before {
      content: '';
      display: block;
      position: fixed;
      height: 50px;
      pointer-events: none;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 9999;
      -webkit-app-region: drag;
    }

    a {
      -webkit-user-drag: none;
    }
  `;

export const textOverFlow = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
`;

export const menuCSS = css`
  background-color: #f7f8fa;
  color: ${theme.colors.MENU_COLOR};
  border: none;
  .ant-menu-title-content {
    user-select: none;
  }
  .ant-menu-item {
    display: flex;
    align-items: center;
    &:hover {
      color: ${theme.colors.MENU_COLOR};
    }
    .ant-menu-title-content {
      user-select: none;
    }
  }
  .ant-menu-item.ant-menu-item-selected:hover {
    color: #ffffff;
  }
  .ant-menu-submenu-active {
    background-color: rgba(37, 49, 69, 0.1);
    border-radius: 2px;
  }

  .ant-menu-item-selected a {
    color: #ffffff;
  }
  .ant-menu-item-selected:after {
    opacity: 0;
  }
  .ant-menu-submenu-selected {
    color: ${theme.colors.MENU_COLOR};
  }
  .ant-menu-submenu-title:hover {
    color: ${theme.colors.MENU_COLOR};
  }
`;

export const customNotification = css`
  .ant-notification-notice {
    width: auto;
    max-width: 392px;
    border-radius: 2px;
    padding: 8px 12px 8px 16px;
    background: #ffffff;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
      0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
  }

  .ant-notification-notice-message {
    font-size: 16px;
    font-weight: 500;
  }

  .ant-notification-notice-with-icon .ant-notification-notice-description {
    margin-left: 28px;
  }

  .ant-notification-notice-icon {
    font-size: 14px;
    line-height: 23px;
  }

  .ant-notification-notice-description {
    color: rgba(26, 34, 48, 0.8);
    padding-right: 20px;
  }

  .ant-notification-notice-close {
    top: 12px;
    right: 10px;
  }
`;
