import {useState} from 'react';

import {modalBodyStyle} from '../constants/theme';

/**
 * @typedef {Object} modalControlObject
 * @property {Boolean} visible
 * @property {Function} onCancel
 * @property {Function} _toggle
 * @property {Boolean} maskClosable
 * @property {Boolean} destroyOnClose
 * @property {Object} bodyStyle
 */

/**
 * @description: Modal 弹窗显隐控制
 * @param {Function} [cancelCallBack] - 点击取消时回调函数
 * @returns {modalControlObject}
 */
export default function useModalControl(cancelCallBack = (v) => v) {
  const [visible, setVisible] = useState(false);

  function _toggle(val = !visible) {
    setVisible(val);
  }

  function onCancel() {
    cancelCallBack();
    setVisible(false);
  }

  return {
    visible,
    onCancel,
    _toggle,
    maskClosable: false,
    destroyOnClose: true,
    bodyStyle: modalBodyStyle,
  };
}
