/* eslint-disable no-useless-escape */
/**
 * 一、至少1个大写英文字母
 * 二、至少1个小写英文字母
 * 三、至少1个阿拉伯数字
 * 四、至少1个以下常规键盘可以输入的字符（中文+英文输入法字符）
 */
export const REG_PASSWORD =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\~\！\@\#\￥\%\……\&\*\（\）\——\+\=\、\|\!\$\^\(\)\_\-\,\.\，\：\；\{\}\【\】\'\"\<\>\《\》\|]).{4,}$/;

export const REG_POSITIVE_INT = /^\+?[1-9][0-9]*$/; // 正整数

/** 身份证 */
export const REG_ID =
  /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/g;

/** 手机号 */
export const REG_MOBILE =
  /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/g;

/** 邮箱 */
export const REG_EMAIL =
  /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/g;

/** 正或负整数小数 */
export const REG_NUMBER = /^[\+\-]?[0-9]+(.{1}[0-9]+)?$/;

/** 汉字 */
export const REG_CN_WORD = /\p{Unified_Ideograph}/u;
