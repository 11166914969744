import {arrayMove, useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import React, {useCallback, useEffect} from 'react';

import {deepClone} from '../../../helpers/utils';
import useSafeState from '../../../hooks/useSafeState';

const makeColumns = (columns) => {
  return columns.map((col) => {
    return {
      ...col,
      onHeaderCell: (column) => {
        return col.onHeaderCell
          ? {
              ...col.onHeaderCell(column),
              id: col.dataIndex,
              draggable: col.draggable === undefined ? true : !!col.draggable,
            }
          : {
              id: col.dataIndex,
              draggable: col.draggable === undefined ? true : !!col.draggable,
            };
      },
    };
  });
};

const makeCell = (components) => {
  return function Cell(props) {
    const {id, draggable, ...rest} = props;
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id,
    });
    const ResizableHeader = components.header.cell;
    const style = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && {
          ...transform,
          scaleX: 1,
          scaleY: 1,
        }
      ),
      transition,
      cursor: 'move',
      ...(isDragging
        ? {
            position: 'relative',
            zIndex: 9999,
          }
        : {}),
    };
    const draggableHeaderProps = draggable
      ? {
          ref: setNodeRef,
          style,
          ...attributes,
          ...listeners,
        }
      : {};
    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <ResizableHeader {...rest} draggableHeaderProps={draggableHeaderProps} />
    );
  };
};

export default function useDraggableHeader({
  headerDraggable,
  columns,
  components,
  setResizableColumns,
}) {
  const [finalColumns, setFinalColumns] = useSafeState(() => {
    return headerDraggable ? makeColumns(columns) : columns;
  });
  const [finalComponents, setFinalComponents] = useSafeState(() => {
    const _components = deepClone(components);
    if (headerDraggable) {
      const cell = makeCell(components);
      _components.header.cell = cell;
    }
    return _components;
  });

  useEffect(() => {
    setFinalColumns(() => {
      return headerDraggable ? makeColumns(columns) : columns;
    });
  }, [columns, setFinalColumns, headerDraggable]);

  useEffect(() => {
    setFinalComponents(() => {
      const _components = deepClone(components);
      if (headerDraggable) {
        const cell = makeCell(components);
        _components.header.cell = cell;
      }
      return _components;
    });
  }, [components, setFinalComponents, headerDraggable]);

  const onDragEnd = useCallback(
    ({active, over}) => {
      let newColumns;
      let activeIndex;
      let overIndex;
      if (active.id !== over?.id && over) {
        setFinalColumns((prev) => {
          activeIndex = prev.findIndex((i) => i.dataIndex === active.id);
          overIndex = prev.findIndex((i) => i.dataIndex === over?.id);
          const ret = arrayMove(prev, activeIndex, overIndex);
          setResizableColumns(ret);
          newColumns = ret;
          return ret;
        });
        return {
          newColumns,
          activeIndex,
          overIndex,
        };
      }
      return false;
    },
    [setFinalColumns, setResizableColumns]
  );

  return {
    components: finalComponents,
    finalColumns,
    onDragEnd,
  };
}
