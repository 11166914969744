import {atom} from 'jotai';

export const filterAtom = atom({
  /** 筛选器是否显示 */
  visible: false,
  /** 当前筛选器与页面中所关联的表单 */
  tableKey: '',
  /**
   * 表单和表单列筛选条件对应关系的集合
   * ```
   * {
   *  tableA: {
   *    aliasNameA: {
   *      keyA: '',
   *      keyB: '',
   *    }
   *  }
   * }
   * ```
   */
  conditions: {},
  /** @type {import('../../TableSheetCopy/type').CustomColumnType} */
  column: {},
});
