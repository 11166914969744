/* eslint-disable no-debugger */
import {Modal, notification} from 'antd';
import {createBrowserHistory, createHashHistory} from 'history';
import jwtDecode from 'jwt-decode';

import {freshToken, logout, rootLogout} from '../api/login';
import {storageKeys} from '../constants/storageKeys';
import {PUBLIC_PATH, ROOT_LOGIN} from '../constants/system';
import {isElectron} from './isElectron';

export const routeHistory = isElectron
  ? createHashHistory()
  : createBrowserHistory();

const push = routeHistory.push;
const replace = routeHistory.replace;

export function generatePath(to, state) {
  return typeof to === 'string'
    ? to.indexOf(PUBLIC_PATH) === 0
      ? to
      : `${PUBLIC_PATH}${to}`
    : {
        ...to,
        pathname:
          to.pathname.indexOf(PUBLIC_PATH) === 0
            ? to.pathname
            : `${PUBLIC_PATH}${to.pathname}`,
      };
}

routeHistory.push = (to, state = {}) => {
  push(generatePath(to, state), state);
};

routeHistory.replace = (to, state = {}) => {
  replace(generatePath(to, state), state);
};

/**
 * @description: 登出执行函数(将clear掉所有storage)
 * @param {boolean} [needTellServiceLogout=true] - 是否要调用登出接口，告知后端删除当前token
 * @returns {void}
 */
export const logoutHandle = async (needTellServiceLogout = true) => {
  const curPathName = window.location.pathname;
  const token = window.localStorage.getItem(storageKeys.TOKEN);
  const userInfo =
    token && token !== 'undefined' && token !== 'null' ? jwtDecode(token) : {};

  const isRootLoginPath = curPathName === ROOT_LOGIN;
  const isRootLoginUser = userInfo.name === 'root';
  const isRoot = isRootLoginPath || isRootLoginUser;

  const {pathname, search} = window.location;
  const lastVisitPath = pathname.split(PUBLIC_PATH)[1] + search;

  /* document.querySelectorAll('iframe').forEach((item) => {
    item.contentWindow.postMessage(
      {
        token: null,
        type: 'stxz',
      },
      '*'
    );
  }); */

  // 保存当前最后访问的路径
  window.sessionStorage.setItem(storageKeys.LAST_VISIT_PATH, lastVisitPath);

  if (needTellServiceLogout) {
    const logoutActionApi = isRoot ? rootLogout : logout;
    await logoutActionApi();
  }

  setTimeout(() => {
    Modal.destroyAll();
    notification.destroy();
    window.sessionStorage.clear();
    window.localStorage.removeItem(storageKeys.TOKEN);
    // 路由跳转提前 兼容页面卸载时需要用到token
    routeHistory.replace(isRoot ? ROOT_LOGIN : '/login');
  }, 100);
};

/**
 * @description: 刷新token目前有两种情况
 * 1 操作过程中失效，无感知刷新
 * 2 更改个人信息后
 * @param {function} [cb] 刷新token后回调函数
 */
export const reFreshToken = async (cb = (v) => v) => {
  window.localStorage.removeItem(storageKeys.TOKEN);
  const tokenRes = await freshToken();
  window.localStorage.setItem(storageKeys.TOKEN, tokenRes?.data);
  cb();
};

/**
 * @description: 检查当前token是否失效，及刷新和登出操作
 * @async
 * @function checkTokenValid
 * @returns {void}
 */
export const checkTokenValid = async () => {
  const token = window.localStorage.getItem(storageKeys.TOKEN);
  if (token && token !== 'undefined') {
    const payload = jwtDecode(token);
    const {exp} = payload;
    const nowTime = Math.round(new Date() / 1000);

    // 操作时token 提前120秒无感知刷新token，避免用户操作时被强制退出，体验更好
    if (nowTime >= exp - 120) {
      await reFreshToken();
    }
    return true;
  }
  // 不存在token时重新登录
  logoutHandle(false);
  return false;
};

// routeHistory.listen(({location, action}) => {

// });
