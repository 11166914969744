import {useAtom} from 'jotai';
import {useEffect} from 'react';

import {pagePermsAtom} from '../atoms/pagePermsAtom';
import request from '../helpers/request';

export function useMenuCodeInRequest() {
  const [pagePerms] = useAtom(pagePermsAtom);

  useEffect(() => {
    const id = request.interceptors.request.use((config) => {
      const {headers} = config;
      headers.mc = pagePerms.menuCode;
      config.headers = headers;
      return config;
    });
    return () => request.interceptors.request.eject(id);
  }, [pagePerms.menuCode]);
}
