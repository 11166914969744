import {css} from '@emotion/react';
import styled from '@emotion/styled';
import React, {useState} from 'react';
import {useInterval} from 'react-use';
import useSWR from 'swr';

import {GET_TEMP_COUNT_DOWN} from '../../../api/system/user';
import dayjs from '../../../helpers/extendedDayjs';
import {logoutHandle} from '../../../helpers/history';
import useUnmountedRef from '../../../hooks/useUnmountedRef';

const TimeBlock = styled.span`
  display: inline-block;
  width: 20px;
  height: 22px;
  line-height: 22px;
  border-radius: 1px;
  margin: 0 2px;
  color: #fec22a;
  background-color: rgba(255, 255, 255, 0.2);
  font-size: 16px;
  font-weight: 600;
`;

const deadlineCSS = css`
  flex: 1;
  text-align: center;
  color: rgba(255, 255, 255, 0.9);
`;

/* 
  userType: 1为普通 2为临时
*/

export default function Deadline() {
  const [remainingDate, setRemainingDate] = useState({});
  const [isMounted, setIsMounted] = useState(true);
  const unmountedRef = useUnmountedRef();

  const {data, mutate: refreshCountDown} = useSWR(GET_TEMP_COUNT_DOWN);

  const expireAndUserType = data?.data;

  const isExpired = expireAndUserType
    ? dayjs(expireAndUserType?.expireTill) < dayjs()
    : false;

  useInterval(
    refreshCountDown,
    expireAndUserType &&
      expireAndUserType.userType === 2 &&
      !unmountedRef.current
      ? 60000
      : null
  );

  useInterval(
    () => {
      setRemainingDate(
        dayjs.duration(dayjs(expireAndUserType?.expireTill).diff(dayjs()))
      );
    },
    expireAndUserType && expireAndUserType.userType === 2 && !isExpired
      ? 1000
      : null
  );

  if (expireAndUserType?.userType === 2 && isExpired && isMounted) {
    logoutHandle();
    setIsMounted(false);
  }

  if (!expireAndUserType || expireAndUserType.userType === 1) {
    return null;
  }
  return (
    <div css={deadlineCSS}>
      账号仍可使用
      <TimeBlock>{remainingDate.$d?.days}</TimeBlock>天
      <TimeBlock>{remainingDate.$d?.hours}</TimeBlock>小时
      <TimeBlock>{remainingDate.$d?.minutes}</TimeBlock>分
      <TimeBlock>{remainingDate.$d?.seconds}</TimeBlock>秒
    </div>
  );
}
