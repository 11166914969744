import {css} from '@emotion/react';
import {Input} from 'antd';
import {useEffect, useRef, useState} from 'react';

import {encrypt} from '../../helpers/jsencrypt';

/**
 * @description 简化版
 * @param {import('antd').InputProps} props
 */
const SecretInput = (props) => {
  const {
    value,
    onChange,
    asteriskCount = 12,
    allowClear = true,
    ...rest
  } = props;

  const newValue = useRef('');
  const inputElRef = useRef();

  const [showValue, setShowValue] = useState('');

  const onFocusHandle = () => {
    inputElRef.current?.focus({
      cursor: 'all',
    });
  };

  const onChangeHandle = (e) => {
    const inputVal = (e.target.value || '').trim();
    const secretVal = encrypt(inputVal);
    newValue.current = inputVal;

    onChange(inputVal ? secretVal : inputVal);
    setShowValue(inputVal);
  };

  useEffect(() => {
    if (newValue.current.length === 0 && value) {
      const showVal = (value || '')
        .substr(0, asteriskCount)
        .padEnd(asteriskCount)
        .replace(/./g, '*');

      setShowValue(showVal);
    }
  }, [asteriskCount, value]);

  const suffixToggle = css`
    .ant-input-suffix {
      display: ${newValue.current.length > 0 ? 'flex' : 'none'};
    }
  `;

  return (
    <Input.Password
      allowClear={allowClear}
      autoComplete='off'
      css={suffixToggle}
      ref={inputElRef}
      value={showValue}
      onChange={onChangeHandle}
      onFocus={onFocusHandle}
      {...rest}
    />
  );
};

export default SecretInput;
