import {css} from '@emotion/react';
import React from 'react';

import {leftCheckStyle, middleCheckStyle, noCheckStyle} from './styles.css';

const CrossCircle = (props) => {
  const {status = 3} = props;
  return (
    <div
      className='circle-wraper'
      css={css`
        ${status === 1
          ? leftCheckStyle
          : status === 2
          ? middleCheckStyle
          : noCheckStyle}
      `}
    >
      <div className='circle circle1' />
      <div className='circle circle2' />
    </div>
  );
};

export default CrossCircle;
