import {css} from '@emotion/react';
import React from 'react';

import {images} from '../../assets';
import CrossCircle from '../../components/CrossCircle';
import {Page} from '../../components/Page';

export default function HomePage() {
  return (
    <Page
      className='HomePage'
      css={css`
        p {
          margin: 24px;
        }
      `}
    >
      <img alt='logo' src={images.logo} />
      <p>
        <span>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Deserunt,
          officia obcaecati error repellat ducimus quae itaque rem qui autem
          dignissimos ab asperiores dolore modi, quas, sapiente totam sunt quo.
          Natus!
        </span>
      </p>
      <CrossCircle />
    </Page>
  );
}
