// @ts-check

import request from '../helpers/request';

/**
 * 登录方法
 * @param {object} params
 * @param {string} params.username
 * @param {string} params.password
 * @param {string} params.code
 * @param {string} params.uuid
 * @returns {
    Promise<{
      access_token: string;
      expires_in: number;
    }>
  }
 */
export function login(params) {
  const {username, password, code, uuid} = params;
  return request({
    url: '/auth/login',
    method: 'post',
    data: {username, password, code, uuid},
  });
}

/**
 * 获取验证码
 * @returns {
    Promise<{
      img: string;
      uuid: string;
    }>
  }
 */
export function getCodeImg() {
  return request({
    url: '/code',
    method: 'get',
  });
}

/**
 * 获取用户详细信息
 * @returns {
    Promise<{
      permissions: string[];
      roles: string[];
      user: object;
    }>
  }
 */
export function getInfo() {
  return request({
    url: '/user/user/getInfo',
    method: 'get',
  });
}

/**
 * 退出方法
 */
export function logout() {
  return request({
    url: '/auth/logout',
    method: 'delete',
  });
}

// /**
//  * @description: 获取当前账号可见路由
//  * @return {*}
//  */
export const freshToken = () => {
  return request({
    url: '/auth/refreshToken',
    method: 'post',
  });
};

/**
 * 登录方法
 * @param {object} params
 * @param {string} params.username
 * @param {string} params.password
 * @param {string} params.code
 * @param {string} params.uuid
 * @returns {
    Promise<{
      access_token: string;
      expires_in: number;
    }>
  }
 */
export function rootLogin(params) {
  const {username, password, code, uuid} = params;
  return request({
    url: '/auth/rootLogin',
    method: 'post',
    data: {username, password, code, uuid},
  });
}

export function rootLogout() {
  return request({
    url: '/auth/rootLoginOut',
    method: 'post',
  });
}
