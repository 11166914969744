import {css} from '@emotion/react';
import {useAtom} from 'jotai';
import React, {useEffect, useRef, useState} from 'react';

import {OPERATION_LOG} from '../../api/system/accountManage';
import {pagePermsAtom} from '../../atoms/pagePermsAtom';
import dayjs from '../../helpers/extendedDayjs';
import {ExportButton} from '../ExportButton';
import LimitRangePicker from '../LimitRangePicker';
import TableSheetCopy from '../TableSheetCopy';
import defColumns from './defColumns';

const format = 'YYYY-MM-DD HH:mm:ss';

/**
 * @description 日志表格
 * @param {import('.').LogProps & import('antd').TableProps} props
 *
 * It can be accessed using:
 *
 * ```js
 *  <LogTable
      onExportClick={() => ({url, params, filename, requestConfig})}
      handleTimeChange={handleTimeChange}
      queryParams={{}}
    >
      some component
    </LogTable>
 * ```
 */
export function LogTable(props) {
  const {
    children,
    columns,
    limit = 90,
    getLogApi = OPERATION_LOG,
    queryParams = {},
    handleTimeChange = () => ({}),
    onExportClick,
    storeColumnWidthKey,
    ...rest
  } = props;

  const [queryTime, setQueryTime] = useState([
    dayjs().subtract(limit, 'day'),
    dayjs(),
  ]);

  const pickerRef = useRef();

  const tableRef = useRef();

  useEffect(() => {
    handleTimeChange(queryTime);
  }, [queryTime, handleTimeChange]);

  const extraParams = {
    startTime: queryTime[0].startOf('day').format(format),
    endTime: queryTime[1].endOf('day').format(format),
    ...queryParams,
  };

  /** @type {import('antd/lib/table').ColumnsType} */
  const tableColumns = columns ? columns : defColumns;

  const [pageInfo] = useAtom(pagePermsAtom);

  return (
    <div className='logTable'>
      <div className='logTable-extra'>{children}</div>
      <TableSheetCopy
        clearFilter={() => {
          pickerRef.current?.reset();
          tableRef.current?.mutate();
        }}
        columns={tableColumns}
        dataApi={getLogApi}
        extraParams={extraParams}
        pagination={{pageSize: 10}}
        ref={tableRef}
        rowKey='id'
        storeColumnWidthKey={storeColumnWidthKey}
        topLeftButtons={[
          onExportClick ? (
            <ExportButton
              twiceCheck
              key={1}
              name='导出日志'
              payload={{functionName: `【${pageInfo.menuName}】-【导出日志】`}}
              size='small'
              onClick={onExportClick}
            />
          ) : (
            false
          ),
          <LimitRangePicker
            handleChange={setQueryTime}
            key={2}
            limit={limit}
            ref={pickerRef}
            size='small'
          />,
          <p
            css={css`
              display: flex;
              align-items: center;
              line-height: 22px;
              font-size: 12px;
              color: rgba(26, 34, 48, 0.4);
            `}
            key={3}
          >
            只支持开始日期和结束日期区间跨度最大为{limit}天的查询
          </p>,
        ].filter(Boolean)}
        {...rest}
      />
    </div>
  );
}
