import {rgba} from 'polished';

const colors = {
  PRIMARY: '#4D5EFF',
  TEXT: '#1A2230',
  BACKGROUND: '#f0f2f5',
  BLOCK: '#FFFFFF',
  SHADOW: rgba('#000D1A', 0.04),
  MENU_COLOR: '#253145',
  FONT_COLOR: '#253145',
  SELECTED_BACKGROUND: rgba(77, 94, 255, 0.08),
  get blacks() {
    const base = '#000D1A';
    return {
      base,
      90: rgba(base, 0.9),
      80: rgba(base, 0.8),
      70: rgba(base, 0.7),
      60: rgba(base, 0.6),
      50: rgba(base, 0.5),
      40: rgba(base, 0.4),
      30: rgba(base, 0.3),
      20: rgba(base, 0.2),
      10: rgba(base, 0.1),
      4: rgba(base, 0.04),
    };
  },
  get whites() {
    const base = '#FFFFFF';
    return {
      base,
      90: rgba(base, 0.9),
      80: rgba(base, 0.8),
      70: rgba(base, 0.7),
      60: rgba(base, 0.6),
      50: rgba(base, 0.5),
      40: rgba(base, 0.4),
      30: rgba(base, 0.3),
      20: rgba(base, 0.2),
      10: rgba(base, 0.1),
      4: rgba(base, 0.04),
    };
  },
};

const scrollbar = {
  COLOR: '#C6CFFF',
  HOVER_COLOR: '#4D5EFF',
  WIDTH: 6,
};

export const theme = {
  colors,
  scrollbar,
};

export const modalBodyStyle = {
  maxHeight: '72vh',
  overflow: 'auto',
};

// 修改UI框架CSS变量，慎改
export const themeColors = {
  primaryColor: '#4D5EFF',
  errorColor: '#E34D59',
  warningColor: '#FAAD14',
  successColor: '#52c41a',
  infoColor: '#1890ff',

  // Input
  inputColor: rgba(26, 34, 48, 1),
  inputPlaceholderColor: rgba(26, 34, 48, 0.4),
  inputBorderColor: rgba(26, 34, 48, 0.15),
  inputHoverborderColor: '#4D5EFF',

  // Button
  btnDisableColor: rgba(26, 34, 48, 0.4),
  btnDisableBg: rgba(26, 34, 48, 0.05),
  btnDisableBorder: rgba(26, 34, 48, 0.06),
};
