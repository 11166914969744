import {css} from '@emotion/react';
import {
  Button,
  Checkbox,
  Collapse,
  Empty,
  Input,
  List,
  Select,
  Space,
} from 'antd';
import React, {useCallback, useRef, useState} from 'react';
import {useEffect} from 'react';
import {useClickAway, useInterval} from 'react-use';
import useSWR from 'swr';

import {
  deleteNotice,
  getMessage,
  GET_NOTICE_TYPE,
  hasReadByIds,
} from '../../../api/system/letter';
import {FlowTextButton} from '../../../components/FlowTextButton';
import IconFont from '../../../components/IconFont';
import {overflowScrollBar} from '../../../global.css';
import {debounce} from '../../../helpers/utils';
import useUnmountedRef from '../../../hooks/useUnmountedRef';
import {useReadLetter} from '../hooks/useReadLetter';

const letterCss = css`
  position: absolute;
  top: 60px;
  right: 80px;
  width: 623px;
  z-index: 200;
  height: auto !important;
  min-height: 200px;
  max-height: 350px;
  background-color: #fff;
  overflow: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  border-radius: 2px;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 13, 26, 0.2);
    border-radius: 10px;
  }
  ${overflowScrollBar}
`;

const letterHeadCss = css`
  align-items: center;
  display: flex;
  padding: 10px 12px;
  padding-right: 0px;
`;

const readBtnCss = css`
  padding: 0;
  height: auto;
  line-height: 1;
`;

/**
 * @param {{
 *  pageCode: String;
 *  name: String;
 *  allNoticeIds: Array;
 *  defaultActiveKey: Array;
 *  defaultCheckIds: Array;
 *  handleUpdate: Function;
 *  handleCheck: Function;
 *  setReadResult: Function;
 * } & import('antd').ListProps} props
 */
function ListFC(props) {
  const {
    pageCode,
    name,
    allNoticeIds,
    handleUpdate,
    switchVisibility,
    handleCheck,
    defaultActiveKey,
    defaultCheckIds,
    setReadResult,
    ...rest
  } = props;
  const [checkedIds, setCheckedIds] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [allChecked, setAllChecked] = useState(false);

  useEffect(() => {
    if (defaultCheckIds.length) {
      const temp = [];
      for (let i = 0; i < defaultCheckIds.length; i++) {
        const item = defaultCheckIds[i];
        if (allNoticeIds.indexOf(item) > -1) {
          temp.push(item);
        }
      }
      setCheckedIds(temp);
    } else {
      setCheckedIds([]);
    }
  }, [defaultCheckIds, allNoticeIds]);

  useEffect(() => {
    setIndeterminate(
      checkedIds.length && checkedIds.length < allNoticeIds.length
    );
    setAllChecked(
      checkedIds.length && checkedIds.length === allNoticeIds.length
    );
  }, [checkedIds, allNoticeIds]);

  function delNotice(data) {
    deleteNotice(data).then((res) => {
      if (res.code === 200) {
        handleUpdate();
        const i = checkedIds.indexOf(data.id);
        if (i > -1) {
          handleCheck([data.id], 'decrease');
          const tmp = [...checkedIds];
          tmp.splice(i, 1);
          setCheckedIds(tmp);
        }
      }
    });
  }

  function readNotice(ids) {
    switchVisibility(false);

    const payload = ids ?? checkedIds;

    hasReadByIds({
      ids: payload,
    }).then((res) => {
      if (res.code === 200) {
        handleUpdate();
        handleCheck(payload, 'decrease');
        if (!ids) {
          setCheckedIds([]);
        }
      }
    });
  }

  /** @param {import('antd/lib/checkbox/Checkbox').CheckboxChangeEventTarget} e */
  function onChange(e) {
    if (indeterminate) {
      const tmp = [];
      for (let i = 0; i < allNoticeIds.length; i++) {
        const item = allNoticeIds[i];
        if (checkedIds.indexOf(item) === -1) {
          tmp.push(item);
        }
      }
      handleCheck(tmp, 'increase');
      setAllChecked(true);
      setCheckedIds(allNoticeIds);
    } else if (e.target.checked) {
      setCheckedIds(allNoticeIds);
      handleCheck(allNoticeIds, 'increase');
    } else {
      setCheckedIds([]);
      handleCheck(allNoticeIds, 'decrease');
    }
  }

  return (
    <Collapse
      className='letter-content'
      css={css`
        border-radius: 0;
        border: none;
        .ant-collapse-item {
          border-bottom: none;
          background-color: #f1f2f5;
          .ant-collapse-header {
            padding: 12px 12px;
            align-items: center;
          }
          .ant-collapse-arrow {
            vertical-align: -3px !important;
            svg {
              font-size: 16px;
            }
          }
          .ant-space-item {
            font-weight: 400;
          }
        }
        .ant-collapse-content-box {
          padding-top: 0;
        }
      `}
      defaultActiveKey={defaultActiveKey}
      expandIcon={(panelProps) => {
        return panelProps.isActive ? (
          <IconFont type='icon-arrow-down' />
        ) : (
          <IconFont type='icon-arrow-right' />
        );
      }}
    >
      <Collapse.Panel
        header={
          <Space>
            {name}
            <Checkbox
              checked={allChecked}
              css={css`
                margin: 0 5px;
                .ant-checkbox + span {
                  color: #1a2230;
                }
              `}
              indeterminate={indeterminate}
              onChange={onChange}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              全选
            </Checkbox>
            {checkedIds.length > 0 && (
              <Button
                css={readBtnCss}
                type='link'
                onClick={(e) => {
                  e.stopPropagation();
                  readNotice();
                }}
              >
                {allChecked ? '全部已读' : '已读'}
              </Button>
            )}
          </Space>
        }
        key={pageCode}
      >
        <List
          renderItem={(innerItem) => (
            <List.Item
              css={css`
                justify-content: space-between;
                align-items: flex-start;
              `}
              key={innerItem.id}
            >
              <Checkbox
                checked={checkedIds.includes(innerItem.id)}
                css={css`
                  .ant-checkbox + span {
                    display: flex;
                  }
                `}
                onChange={(e) => {
                  if (e.target.checked) {
                    handleCheck([innerItem.id], 'increase');
                    setCheckedIds([...checkedIds, innerItem.id]);
                  } else {
                    const temp = [...checkedIds];
                    temp.splice(temp.indexOf(innerItem.id), 1);
                    setCheckedIds(temp);
                    handleCheck([innerItem.id], 'decrease');
                  }
                }}
              >
                <div
                  css={css`
                    margin-right: 10px;
                    color: #999999;
                  `}
                >
                  {innerItem.createTime}
                </div>
                <div
                  css={css`
                    flex: 1;
                    color: #1a2230;
                  `}
                >
                  {innerItem.noticeContent}
                </div>
              </Checkbox>
              <Space>
                <Button
                  key={1}
                  size='small'
                  type='link'
                  onClick={() => {
                    readNotice([innerItem.id], true);
                    setReadResult({
                      noticeType: innerItem.noticeType,
                      pageCode,
                      content: innerItem,
                      queryParams: JSON.parse(innerItem.paramData),
                    });
                  }}
                >
                  查看
                </Button>
                <FlowTextButton
                  key={2}
                  size='small'
                  textMap={[
                    {
                      label: '删除',
                      nextLable: '确认删除',
                      value: '1',
                    },
                  ]}
                  type='link'
                  value='1'
                  onClick={() =>
                    delNotice({
                      id: innerItem.id,
                    })
                  }
                />
              </Space>
            </List.Item>
          )}
          {...rest}
        />
      </Collapse.Panel>
    </Collapse>
  );
}

export function Letter({visible, exposeCount, switchVisibility}) {
  const [letterList, setLetterList] = useState([]);
  const [checkedIds, setCheckedIds] = useState([]);
  const [indeterminateAll, setIndeterminateAll] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [searchingCode, setSearchingCode] = useState('');
  const [searchingContent, setSearchingContent] = useState('');
  const [loadingRead, setLoadingRead] = useState(false);
  const [allNoticeIds, setAllNoticeIds] = useState([]);
  const unMountedRef = useUnmountedRef();

  const ref = useRef(null);

  const {readResult, setReadResult} = useReadLetter();

  useEffect(() => {
    if (typeof readResult?.result === 'function') {
      readResult.result();
    }
  }, [readResult]);

  useClickAway(ref, (e) => {
    if (visible) {
      let target = e.target;
      while (target) {
        if (target.className?.indexOf('letterButton') > -1) {
          return;
        }
        target = target.parentNode;
      }
      switchVisibility(false);
    }
  });

  const {data: noticeTypes, mutate: mutateNoticeTypes} =
    useSWR(GET_NOTICE_TYPE);

  const updateLetterList = useCallback(() => {
    return getMessage({
      menuCode: searchingCode,
      noticeContent: searchingContent,
    })
      .then((res) => {
        if (res.code === 200) {
          const tmp = [];
          res.data.tableList.forEach((item) => {
            item.infoList.forEach((innerItem) => tmp.push(innerItem.id));
          });
          setAllNoticeIds(tmp);
          setLetterList(res.data.tableList);
          exposeCount(res.data.count);
          if (res.data.count === 0) {
            switchVisibility(false);
          }
        }
      })
      .catch((e) => {
        //
      });
  }, [searchingCode, searchingContent, exposeCount, switchVisibility]);

  useEffect(() => {
    updateLetterList();
    mutateNoticeTypes();
    setCheckedIds([]);
    setIndeterminateAll(false);
    setAllChecked(false);
  }, [searchingCode, searchingContent, updateLetterList, mutateNoticeTypes]);

  useEffect(() => {
    setIndeterminateAll(
      checkedIds.length && checkedIds.length < allNoticeIds.length
    );
    setAllChecked(
      checkedIds.length && checkedIds.length === allNoticeIds.length
    );
  }, [checkedIds, allNoticeIds]);

  useInterval(
    () => {
      updateLetterList();
      mutateNoticeTypes();
    },
    unMountedRef.current ? null : 60000
  );

  function readNotice() {
    setLoadingRead(true);

    hasReadByIds({
      ids: checkedIds,
    })
      .then((res) => {
        if (res.code === 200) {
          setCheckedIds([]);
          updateLetterList();
          mutateNoticeTypes();
        }
      })
      .finally(() => {
        setLoadingRead(false);
      });
  }

  function handleCheck(ids, type) {
    if (type === 'increase') {
      setCheckedIds(checkedIds.concat(ids));
    } else {
      const tmp = [...checkedIds];
      for (let i = 0; i < ids.length; i++) {
        tmp.splice(tmp.indexOf(ids[i]), 1);
      }
      setCheckedIds(tmp);
    }
  }

  return (
    <div
      className='letter'
      css={letterCss}
      ref={ref}
      style={{display: visible ? 'block' : 'none'}}
    >
      <h4
        css={css`
          color: #1a2230;
          padding-left: 12px;
          padding-top: 12px;
          font-size: 16px;
        `}
      >
        通知
      </h4>
      <div className='letter-head' css={letterHeadCss}>
        <Space size={10}>
          <Select
            css={css`
              width: 160px;
              .ant-select-arrow {
                font-size: 10px;
              }
            `}
            defaultValue=''
            dropdownClassName='letter-select'
            getPopupContainer={() => document.querySelector('.letter')}
            listHeight={150}
            onChange={setSearchingCode}
          >
            <Select.Option key='' value=''>
              全部
            </Select.Option>
            {noticeTypes?.data?.map((item) => (
              <Select.Option key={item.menuCode} value={item.menuCode}>
                {item.menuName}
              </Select.Option>
            ))}
          </Select>
          <Input.Search
            allowClear
            css={css`
              width: 295px;
              .ant-btn {
                border-left: none;
              }
              .ant-btn-icon-only > * {
                color: rgba(26, 34, 48, 0.25);
              }
            `}
            placeholder='请输入搜索内容'
            onChange={debounce((e) => {
              setSearchingContent(e.target.value);
            }, 200)}
          />
          <Checkbox
            checked={allChecked}
            indeterminate={indeterminateAll}
            onChange={(e) => {
              if (indeterminateAll) {
                setAllChecked(true);
                setCheckedIds(allNoticeIds);
              } else if (e.target.checked) {
                setCheckedIds(allNoticeIds);
              } else {
                setCheckedIds([]);
              }
            }}
          >
            全选
          </Checkbox>
          {checkedIds.length > 0 && (
            <Button
              css={readBtnCss}
              loading={loadingRead}
              type='link'
              onClick={readNotice}
            >
              {allChecked ? '全部已读' : '已读'}
            </Button>
          )}
        </Space>
      </div>
      {letterList.map((item) => (
        <ListFC
          allNoticeIds={item.infoList.map((innerItem) => innerItem.id)}
          dataSource={item.infoList}
          defaultActiveKey={item.pageCode}
          defaultCheckIds={checkedIds}
          handleCheck={handleCheck}
          handleUpdate={() => {
            updateLetterList();
            mutateNoticeTypes();
          }}
          itemLayout='horizontal'
          key={item.menuCode}
          name={item.menuName}
          pageCode={item.pageCode}
          setReadResult={setReadResult}
          switchVisibility={switchVisibility}
        />
      ))}
      {letterList.length === 0 && <Empty />}

      {readResult?.result &&
        typeof readResult.result === 'object' &&
        readResult.result}
    </div>
  );
}
