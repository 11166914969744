import {css} from '@emotion/react';
import {useAtom} from 'jotai';
import React, {useEffect} from 'react';
import useSWR from 'swr';

import {GET_USER_INFO} from '../../../api/system/user';
import {pagePermsAtom} from '../../../atoms/pagePermsAtom';
import {userInfoAtom} from '../../../atoms/userInfoAtom';
import {overflowScrollBar} from '../../../global.css';
import {aesDecrypt} from '../../../helpers/cryptoByAes';
import AccountInfoCard from './components/AccountInfoCard';
import AccountPermTable from './components/AccountPermTable';
import ActionLogsTable from './components/ActionLogsTable';
import StationLetterTable from './components/StationLetterTable';
import MyContext from './helpers/MyContext';

const PersonalCenter = () => {
  const [, setPagesPermAtom] = useAtom(pagePermsAtom);
  const [, setUserInfo] = useAtom(userInfoAtom);
  const {data: userInfo, mutate: freshUserInfo} = useSWR(GET_USER_INFO);

  const decryptedUserInfo = userInfo?.data
    ? {
        data: {
          ...userInfo?.data,
          phonenumber: aesDecrypt(userInfo?.data.phonenumber),
          idCard: aesDecrypt(userInfo?.data.idCardCode),
        },
      }
    : userInfo;

  useEffect(() => {
    setUserInfo((pre) => ({
      ...pre,
      ...userInfo?.data,
    }));
  }, [userInfo, setUserInfo]);

  useEffect(() => {
    setPagesPermAtom((pre) => ({
      ...pre,
      type: 2,
      menuCode: 'system',
      // pageCode: USER_PERMS_MAP.get('个人中心'),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      css={css`
        ${overflowScrollBar}
        height: 100%;
        overflow: auto;
        overflow-x: hidden;
      `}
    >
      <MyContext.Provider
        value={{freshUserInfo, userInfo: decryptedUserInfo?.data ?? {}}}
      >
        <AccountInfoCard
          userInfo={
            userInfo?.data
              ? {
                  data: {
                    ...userInfo.data,
                    phonenumber: decryptedUserInfo?.data.phonenumber,
                  },
                }
              : userInfo
          }
        />
      </MyContext.Provider>
      <div style={{marginTop: 12}}>
        <AccountPermTable userInfo={userInfo} />
      </div>

      <div style={{marginTop: 12}}>
        <StationLetterTable userId={userInfo?.data?.userId} />
      </div>

      <div style={{marginTop: 12}}>
        <ActionLogsTable userInfo={userInfo} />
      </div>
    </div>
  );
};

export default PersonalCenter;
