import {css} from '@emotion/react';
import React from 'react';

export default function SvgIcon(props) {
  const {iconClass, className = '', ...rest} = props;

  return (
    <svg
      aria-hidden='true'
      className={className}
      css={css`
        vertical-align: -0.15em;
        overflow: hidden;
      `}
      fill='currentColor'
      focusable='false'
      height='1em'
      width='1em'
      {...rest}
    >
      <use xlinkHref={`#${iconClass}`} />
    </svg>
  );
}
