import request from '../../helpers/request';

/** 系统通知列表 */
export const NOTICE_LIST = '/user/notice/list';

/** 系统通知-获取通知用户 */
export const QUERY_NOTICE_USER_TREE = '/user/groupUser/getGroupUserTree';

/** 系统通知-提醒所有 */
export function noticeRemind(noticeId) {
  return request({
    url: `/user/notice/remind/${noticeId}`,
    method: 'put',
  });
}

/** 系统通知-单个通知、单个用户提醒 */
export function userRemind(id) {
  return request({
    url: `/user/notice/userRemind/${id}`,
    method: 'put',
  });
}

/** 系统通知-新建通知 */
export function noticeAdd(data) {
  return request({
    url: '/user/notice/add',
    method: 'post',
    data,
  });
}

/** 系统通用设置-检查是否要二次身份验证 */
export const CHECKNEEDTWICECHECK = '/user/user/checkPwdFlag';

/** 系统通用设置-进行二次身份验证 */
export function runTwiceCheckPwd(data) {
  return request({
    url: '/user/user/checkPwd',
    method: 'post',
    data,
  });
}

/** 系统通用设置-更新系统设置 */
export function updateSysSetting(data) {
  return request({
    url: '/user/setting/updateSysSetting',
    method: 'post',
    data,
  });
}
/** 系统通用设置-更新系统设置 */
export function runEmailTest(data) {
  return request({
    url: '/user/setting/mailTest',
    method: 'post',
    data,
  });
}

/** 系统通用设置-更新微观指标系统设置 */
export function updateSysMisSetting(data) {
  return request({
    url: '/user/setting/updateMisSetting',
    method: 'post',
    data,
  });
}

/** 系统通用设置-获取系统设置 */
export const INDEX_SYS_SETTING = '/user/setting/getIndexSysSetting';

/* 字典类型 */
export const DICT_TYPE_LIST = '/user/dict/data/type';
