import {css} from '@emotion/react';
import {Badge} from 'antd';
import React, {useState} from 'react';
import ReactDOM from 'react-dom';

import IconFont from '../../../components/IconFont';
import {Letter} from './Letter';

export default function LetterButton() {
  const [letterCount, setLetterCount] = useState(0);
  const [viewState, setViewState] = useState(false);

  return (
    <>
      <div
        className='letterButton'
        css={css`
          cursor: ${letterCount > 0 ? 'pointer' : 'default'};
        `}
        onClick={() => {
          if (letterCount === 0) return;
          setViewState(!viewState);
        }}
      >
        <Badge
          color='#ff6146'
          count={letterCount}
          css={css`
            line-height: 12px;
            .ant-badge-count {
              box-shadow: none;
              z-index: 1;
              padding: 0 4px;
            }
          `}
          offset={[-4, 0]}
          size='small'
        >
          <IconFont
            className='icon icon-message'
            css={css`
              margin-right: 4px;
              width: 12px;
              height: 12px;
            `}
            type='icon-message'
          />
        </Badge>
        <span className='text'>消息中心</span>
      </div>
      {ReactDOM.createPortal(
        <Letter
          exposeCount={setLetterCount}
          switchVisibility={setViewState}
          visible={viewState}
        />,
        document.getElementById('root')
      )}
    </>
  );
}
